<mat-list>
  <mat-list-item
    id="checkout_view_address_list_item{{ i }}"
    *ngFor="let address of addresses; let i = index"
    (click)="clickAddress(address)"
    [class.selected-address]="selectable && selectedShippingAddressRef === address.shipping_address_ref"
  >
    <div class="mat-body-2 text text-content mb-2" id="checkout_view_address_list_item{{ i }}-address">
      <div class="address-list-item__recipient_name mb-4" id="checkout_view_address_list_item{{ i }}_name">
        {{ address.recipient_name }}
      </div>
      <div class="address-list-item__recipient_details mb-2" id="checkout_view_address_list_item{{ i }}_address">
        {{ [address?.street_number, address?.street_name, address?.apartment].join(' ').trim() }}
      </div>
      <div
        *ngIf="address?.rural_postal_address"
        class="address-list-item__recipient_details mb-2"
        id="checkout_view_address_list_item{{ i }}_address_rural"
      >
        {{ address?.rural_postal_address }}
      </div>
      <div id="checkout_view_address_list_item{{ i }}_address2" class="address-list-item__recipient_details">
        <span>{{ [address?.city, address?.province, address?.country, address?.postal_code].join(', ').trim() }}</span>
      </div>
      <mat-chip-listbox
        class="mt-16"
        [attr.aria-label]="'ARIA_LABEL.ADDRESS_FLAGS' | translate"
        *ngIf="defaultShippingAddressRef === address.shipping_address_ref"
      >
        <mat-basic-chip
          class="default-chip primary-chip"
          id="address-list_default-chip"
          [attr.aria-label]="'ARIA_LABEL.DEFAULT_ADDRESS' | translate"
        >
          <mat-icon [attr.aria-label]="'ARIA_LABEL.CHECK_CIRCLE' | translate">check_circle</mat-icon>
          <div class="mat-caption">{{ 'COMMON.DEFAULT' | translate }}</div>
        </mat-basic-chip>
      </mat-chip-listbox>
    </div>
    <pbb-menu
      *ngIf="showMenuButton"
      style="align-self: center"
      (click)="$event.stopPropagation()"
      id="address_item{{ i }}_menu"
      idLabel="address_item{{ i }}"
      menuAriaLabel="{{ [address?.street_number, address?.street_name, address?.apartment].join(' ').trim() }}"
      [options]="options"
      [item]="address"
      [itemRef]="address.shipping_address_ref"
      [itemIndex]="i"
      (menuAction)="menuAction.emit($event)"
    ></pbb-menu>
  </mat-list-item>
</mat-list>
<hr class="mt-24 mb-24" />
