import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShippingAddress } from '@api/model/shippingAddress';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MenuOption, MenuAction, MenuComponent } from '../menu/menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { NgFor, NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';

export const ADDRESS_ACTION = {
  DEFAULT: 'default',
  CHANGE: 'change',
  REMOVE: 'remove',
};
@Component({
  selector: 'pbb-address-list',
  templateUrl: './address-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatListModule, NgFor, NgIf, MatChipsModule, MatIconModule, MenuComponent, TranslateModule],
})
export class AddressListComponent implements OnInit {
  @Input() addresses!: Array<ShippingAddress>;
  @Input() defaultShippingAddressRef!: string;
  @Input() showMenuButton = true;
  @Input() selectable = true;
  @Input() selectedShippingAddressRef?: string;
  @Output() clickShippingAddress$ = new EventEmitter<ShippingAddress>();
  @Output() menuAction: EventEmitter<MenuAction> = new EventEmitter();

  options: Array<MenuOption> = [
    {
      action: ADDRESS_ACTION.DEFAULT,
      label: 'COMMON.VIEW_ADDRESS.MENU.SET_DEFAULT',
      icon: 'check',
      showConfirm: false,
      disabled: (item) => this.defaultShippingAddressRef === item.shipping_address_ref,
    },
    {
      action: ADDRESS_ACTION.CHANGE,
      label: 'COMMON.VIEW_ADDRESS.MENU.CHANGE_ADDRESS',
      icon: 'edit',
      showConfirm: false,
      clicked: (item, index) => {
        this.router.navigate(['edit', index], {
          relativeTo: this.activatedRoute,
        });
      },
    },
    {
      action: ADDRESS_ACTION.REMOVE,
      label: 'COMMON.VIEW_ADDRESS.MENU.REMOVE_ADDRESS',
      icon: 'delete',
      showConfirm: true,
      confirmCancel: 'COMMON.BUTTON.CANCEL',
      confirmTitle: 'COMMON.VIEW_ADDRESS.MENU.REMOVE_TITLE',
      color: '#CA0000',
      confirmSubtitle: (item: ShippingAddress) =>
        this.translateService.instant('COMMON.VIEW_ADDRESS.MENU.REMOVE_TEXT', {
          address: `${item?.street_number || ''} ${item.street_name} ${item?.apartment || ''}`,
        }),
      closeOnSuccess: true,
      confirmButton: 'COMMON.BUTTON.REMOVE',
      disabled: (item) => this.addresses.length === 1 || this.selectedShippingAddressRef === item.shipping_address_ref,
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.addresses?.length === 1) {
      this.selectedShippingAddressRef = this.addresses[0].shipping_address_ref;
    }
  }

  clickAddress(address: ShippingAddress): void {
    this.selectedShippingAddressRef = address.shipping_address_ref;
    this.clickShippingAddress$.emit(address);
  }
}
